<template>
  <div class="modal is-active">
    <div class="modal-background" />
    <div class="modal-content box">
      <!-- Any other Bulma elements you want -->
      <div>
        <span class="content">
          <h1>Add District</h1>
          <p>Just the Clever OID is needed for a Clever district.</p>
        </span>
      </div>
      <div id="addDistrictForm" class="content">
        <b-field label="Name">
          <b-input v-model="name" />
        </b-field>
        <b-field label="Clever OID">
          <b-input v-model="cleverOid" />
        </b-field>
        <b-field label="Contact First Name">
          <b-input v-model="contactFirstName" />
        </b-field>
        <b-field label="Contact Last Name">
          <b-input v-model="contactLastName" />
        </b-field>
        <b-field label="Contact Title">
          <b-input v-model="contactTitle" />
        </b-field>
        <b-field label="Contact Email">
          <b-input v-model="contactEmail" />
        </b-field>
        <b-field label="Contact Clever Id">
          <b-input v-model="contactCleverOid" />
        </b-field>
        <b-field label="MDR">
          <b-input v-model="mdr" />
        </b-field>
        <b-field label="NCES">
          <b-input v-model="nces" />
        </b-field>
        <b-field label="Clever Portal URL">
          <b-input v-model="cleverPortalURL" />
        </b-field>
        <b-field label="Login Methods">
          <b-input v-model="loginMethods" />
        </b-field>
        <b-field label="Status">
          <b-select v-model="status" placeholder="Status">
            <option key="pending" value="pending">
              Pending
            </option>
            <option key="active" value="active">
              Active
            </option>
            <option key="inactive" value="inactive">
              Inactive
            </option>
          </b-select>
        </b-field>
        <b-field>
          <button class="button is-link is-pulled-right" @click="addDistrict">
            Add
          </button>
        </b-field>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" @click="closeView()" />
  </div>
</template>
<script>
import { request } from '@/edshed-common/api'
export default {
  name: 'AddDistrictView',
  data (router) {
    return {
      name: '',
      cleverOid: '',
      contactFirstName: '',
      contactLastName: '',
      contactTitle: '',
      contactEmail: '',
      contactCleverOid: '',
      mdr: '',
      nces: '',
      cleverPortalURL: '',
      loginMethods: 'Clever Badges,Clever Passwords',
      status: 'pending'
    }
  },
  methods: {
    closeView () {
      this.$emit('close')
    },
    addDistrict () {
      const { name, cleverOid, contactFirstName, contactLastName, contactTitle, contactEmail, contactCleverOid, mdr, nces, cleverPortalURL, loginMethods, status } = this
      request('POST', 'districts', { name, cleverOid, contactFirstName, contactLastName, contactTitle, contactEmail, contactCleverOid, mdr, nces, cleverPortalURL, loginMethods, status })
        .then((response) => {
          this.$buefy.toast.open({
            message: 'Saved',
            type: 'is-success'
          })
          this.closeView()
          // this.searchDidChange()
          // console.log('readerData: ' + this.readerData)
        })
        .catch((error) => {
          alert(error)
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    }
  }
}
</script>
<style>
#addDistrictForm .field .label {
    text-align: left !important;
}
</style>
