<template>
  <section class="section">
    <div class="row center-block">
      <div class="col-md-12">
        <div class="box box-info">
          <div class="box-header with-border content">
            <div class="content">
              <h2 class="is-pulled-left">
                Districts / Trusts
                <span class="tag is-danger">SUPERUSER</span>
              </h2>
              <div class="box-tools pull-right">
                <button type="button" class="button" @click="showAddDistrict">
                  <span class="icon"><i class="mdi mdi-plus" /></span> <span>Add</span>
                </button>
              </div>
            </div>
          </div>
          <!-- /.box-header -->
          <div class="box-body">
            <b-field>
              <b-input
                v-model="searchPhrase"
                placeholder="Search..."
                type="search"
                icon-pack="fas"
                icon="search"
                expanded
                @keyup.native.enter="searchDidChange"
                @input="searchDidChange"
              />
              <p class="control">
                <button :class="{'button': true, 'is-link': true}" @click="searchDidChange">
                  Search
                </button>
              </p>
            </b-field>
            <b-table
              v-if="filteredDistrictsData"
              :data.sync="filteredDistrictsData"
              :paginated="false"
              :mobile-cards="true"
              :striped="true"
              :selectable="false"
              :sort="true"
            >
              <template slot-scope="props">
                <b-table-column field="id" label="ID" sortable>
                  <router-link :to="'/subscriptions?q=' + props.row.id">
                    #{{ props.row.id }}
                  </router-link>
                </b-table-column>
                <b-table-column field="name" label="Name">
                  {{ props.row.school_name }}
                </b-table-column>
                <b-table-column field="name" label="Name">
                  <p>Schools:</p>
                  <div v-if="props.row.sub_schools">
                    <div v-for="sch in props.row.sub_schools.split(',')" :key="'sch-' + sch">
                      <router-link :to="'/subscriptions?q=' + sch">
                        #{{ sch }}
                      </router-link>
                    </div>
                  </div>
                </b-table-column>
                <b-table-column :key="props.row.id" label="">
                  <button class="button is-link is-pulled-right" @click="showDetails(props.row)">
                    Details
                  </button>
                </b-table-column>
              </template>
              <template slot="empty">
                <section class="section">
                  <div class="content has-text-grey has-text-centered content">
                    <p>
                      <b-icon
                        custom-class="far"
                        pack="fa"
                        icon="frown"
                        size="is-large"
                      />
                    </p>
                    <p>Nothing here.</p>
                  </div>
                </section>
              </template>
            </b-table>
          </div>
        </div>
      </div>
    </div>
    <DistrictInfo v-if="detailView" :district="detailView" @close="hideDetailView" />
    <AddDistrict v-if="addDistrictVisible" @close="hideAddDistrict" />
  </section>
</template>
<script>
import { request } from '@/edshed-common/api'
import DistrictInfo from '@/components/views/components/DistrictInfo'
import AddDistrict from '@/components/views/components/AddDistrict'
export default {
  name: 'DistrictsView',
  components: {
    DistrictInfo,
    AddDistrict
  },
  data (router) {
    return {
      districtsData: null,
      filteredDistrictsData: null,
      searchPhrase: '',
      detailView: null,
      addDistrictVisible: false
    }
  },
  mounted () {
    if (!this.$store.state.user.superuser) {
      this.$router.push('/noaccess')
    } else {
      this.getDistricts()
    }
  },
  methods: {
    getDistricts () {
      request('GET', 'districts', null)
        .then((response) => {
          const data = response.data
          this.districtsData = data.districts
          this.searchDidChange()
          // this.searchDidChange()
          // console.log('readerData: ' + this.readerData)
        })
        .catch((error) => {
          this.loading = false
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    searchDidChange () {
      if (this.searchPhrase === '') {
        this.filteredDistrictsData = this.districtsData
      } else {
        this.filteredDistrictsData = this.districtsData.filter(s => s.school_name.toLowerCase().includes(this.searchPhrase.toLowerCase()))
      }
    },
    showDetails (district) {
      this.detailView = district
    },
    hideDetailView () {
      this.detailView = null
    },
    showAddDistrict () {
      this.addDistrictVisible = true
    },
    hideAddDistrict () {
      this.addDistrictVisible = false
      this.getDistricts()
    }
  }
}
</script>
