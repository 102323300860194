<template>
  <div class="modal is-active">
    <div class="modal-background" />
    <div class="modal-content box">
      <!-- Any other Bulma elements you want -->
      <div>
        <span class="content">
          <h1 v-if="district">{{ district.name }}</h1>
        </span>
      </div>
      <div v-if="district" class="content">
        <p>{{ district.sub_schools }}</p>
        <p><b>Clever OID:</b> <span class="tag">{{ district.clever_oid }}</span></p>
        <p><b>Contact Name:</b> {{ district.contact_firstname }} {{ district.contact_lastname }}</p>
        <p><b>Contact Title:</b> {{ district.contact_title }}</p>
        <p><b>Contact Email:</b> <a :href="'mailto:' + district.contact_email">{{ district.contact_email }}</a></p>
        <p><b>Contact Clever OID:</b> <span v-if="district.contact_clever_oid" class="tag">{{ district.contact_clever_oid }}</span></p>
        <p><b>MDR:</b> {{ district.mdr }}</p>
        <p><b>NCES:</b> {{ district.nces }}</p>

        <p><b>Clever Portal URL:</b> <a :href="district.clever_portal_url">{{ district.clever_portal_url }}</a></p>
        <p><b>Last sync:</b> <small>{{ district.last_sync || 'Never' }}</small></p>
        <p>
          <b-field label="Status: " class="is-pulled-left" custom-class="is-paddinglessleft">
            <b-select v-model="status" placeholder="Select a name">
              <option key="pending" value="pending">
                Pending
              </option>
              <option key="active" value="active">
                Active
              </option>
              <option key="inactive" value="inactive">
                Inactive
              </option>
            </b-select>
            <p class="control">
              <button class="button is-link" @click="saveDistrictStatus">
                Save
              </button>
            </p>
          </b-field>
          <span v-if="district.clever_oid && district.status == 'active'" class="is-pulled-right"><button class="button" @click="syncClever(district.clever_oid)"><span class="icon"><img src="/img/clevericon.png"></span> <span>Sync Clever</span></button></span>
        </p>
      </div>
    </div>
    <button class="modal-close is-large" aria-label="close" @click="closeView()" />
  </div>
</template>
<script>
import { request } from '@/edshed-common/api'
export default {
  name: 'DistrictInfo',
  props: ['district'],
  data (router) {
    return {
      status: null
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.status = this.district.status
    })
  },
  methods: {
    closeView () {
      this.$emit('close')
    },
    syncClever (districtOid) {
      request('POST', 'districts/' + districtOid + '/cleversync', null)
        .then((response) => {
          const data = response.data
          console.log(data)
          // this.searchDidChange()
          // console.log('readerData: ' + this.readerData)
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    },
    saveDistrictStatus () {
      const status = this.status
      request('POST', 'districts/' + this.district.id + '/status', { status })
        .then((response) => {
          console.log(data)
          // this.searchDidChange()
          // console.log('readerData: ' + this.readerData)
        })
        .catch((error) => {
          console.log(error)
          if (error.response.status === 403) {
            console.log('FORBIDDEN')
            this.$router.push('/logout')
          }
          this.response = 'Details incorrect'
        })
    }
  }
}
</script>
<style>
  .is-paddinglessleft {
    padding-left: 0;
  }
</style>
